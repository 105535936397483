/* src/components/LoadingScreen.css */

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    backdrop-filter: blur(5px);
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #4a4e69;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    will-change: transform;
  }
  
  .loading-screen h2 {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #4a4e69;
    font-weight: 500;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  